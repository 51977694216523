import { graphql } from 'gatsby'
import React from 'react'

import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { Link } from '../../components/common/link'
import { VideoPlayer } from '../../components/common/video-player'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Jak to všechno zvládá?" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Jak to všechno zvládá?" />
        <ContentSubPage>
          <p>
            <b>Filip Bartek</b>, podnikavý a mnohostranně talentovaný člověk,
            moderátor/voiceover Hitrádia Zlín, kreativec a živnostník (výrobce
            dřevěných stavebnic{' '}
            <Link to="https://www.stouni.cz/" target="_blank">
              Stouni
            </Link>
            ), muzikant (frontmen skupiny{' '}
            <Link to="http://www.nohow.cz/" target="_blank">
              Nohow
            </Link>
            ), ale také aktivní tatínek a spisovatel, který pro svého malého
            synka napsal knihu o spinkání (
            <Link
              to="https://www.databazeknih.cz/knihy/o-vile-spinkalce-433329"
              target="_blank"
            >
              O víle Spinkalce
            </Link>
            )... Že je toho na jednoho člověka docela dost? To určitě! Jsme
            rádi, že mu s organizací práce a života pomáhá i naše aplikace
            Flagis. Koukněte na video, ve kterém se s námi podělil o jeho běžný
            pracovní den. Díky Filipe!
          </p>
          <br />
          <VideoPlayer url="https://youtu.be/hRbhm4iltME" />
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
